import React from 'react';

import fileData from '../../../interface/props/table/files';
import Dot from './Dot';

import styles from './Status.module.css';

type Props = {
  document: fileData;
};

const Status: React.FC<Props> = ({ document }) => {
  return (
    <div className={styles['status-container']}>
      <Dot status={document.uploaded} />
      {/* <Dot status={document.isProcessed} /> */}
      {''} <Dot status={document.scheduled} />
    </div>
  );
};

export default Status;
