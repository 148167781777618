// React
import { useState } from 'react';

// Components
import UploadThumbnail from './UploadThumbnail/UploadThumbnail';

// Mantine
import { Modal, Tabs } from '@mantine/core';

// Types
import fileDataType from '../../../interface/data/FileData';
import UpdateDetails from './UpdateDetails/UpdateDetails';

interface EditDetailsProps {
  opened: boolean;
  title: string;
  document: fileDataType;
  close: () => void;
  open?: () => void;
  centersFaculty: {
    group: string;
    label: string;
    value: string;
  }[];
}

const EditDetails: React.FC<EditDetailsProps> = ({
  opened,
  close,
  document,
  centersFaculty,
}) => {
  const [activeTab, setActiveTab] = useState<string | null>('details');

  return (
    <Modal
      opened={opened}
      onClose={close}
      size='31.5rem'
      title={
        <div
          style={{
            fontSize: '1.25rem',
            fontWeight: 'bold',
          }}
        >
          Edit Details or Thumbnail
        </div>
      }
      centered
    >
      <Tabs color='teal' value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value='details'>Edit Details</Tabs.Tab>
          <Tabs.Tab value='thumbnail'>Edit Thumbnail</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value='details'>
          <UpdateDetails
            close={close}
            document={document}
            centersFaculty={centersFaculty}
          />
        </Tabs.Panel>
        <Tabs.Panel value='thumbnail'>
          <UploadThumbnail
            closeModal={close}
            fileId={document._id}
            fileData={document}
          />
        </Tabs.Panel>
      </Tabs>
    </Modal>
  );
};

export default EditDetails;
