import React from 'react';

import fileData from '../../../interface/props/table/files';
import parseDateTime from '../../../utils/getDateAndTime';

type Props = {
  document: fileData;
};

const Date: React.FC<Props> = ({ document }) => {
  
  return (
    <div style={{ textAlign: 'center' }}>
      {parseDateTime(document.startTime).date}
    </div>
  );
};

export default Date;
