const csvHeaders = [
  { label: 'id', key: '_id' },
  { label: 'Schedule Id', key: 'scheduleId' },
  { label: 'key', key: 'fileKey' },
  { label: 'Center', key: 'center' },
  { label: 'Room', key: 'room' },
  { label: 'Batch Name', key: 'batchName' },
  { label: 'Batch Id', key: 'batchId' },
  { label: 'Filename', key: 'fileName' },
  { label: 'File-Type', key: 'fileType' },
  { label: 'Start-time', key: 'startTime' },
  { label: 'End-time', key: 'stopTime' },
  { label: 'Uploaded', key: 'uploaded' },
  { label: 'Processed', key: 'isProcessed' },
  { label: 'Scheduled', key: 'scheduled' },
];

export { csvHeaders };
