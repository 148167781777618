// React
import { useState, forwardRef } from 'react';

// Mantine Dev
import { Select } from '@mantine/core';
import type { SelectItemProps } from '@mantine/core';

// Redux
import { useSelector } from 'react-redux';
import type { RootState } from '../../../app/store';

type Props = {};

const OnlineClients = (props: Props) => {
  const { pingData } = useSelector((state: RootState) => state.pingSlice);

  const [value, setValue] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChange = (val: string) => {
    // console.log('Value: ', val, 'Type of Value : ', typeof val);
    setValue(val);
  };

  const handleSearchChange = (val: any) => {
    // console.log('Value : ', val, 'Type of Value: ', typeof val);
    setSearchValue(val);
  };

  // console.log('Value from OneOption.tsx - useState : ', value);

  const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ label, ...others }: SelectItemProps, ref) => (
      <div ref={ref} {...others}>
        <div>{label}</div>
      </div>
    )
  );

  return (
    <Select
      data={pingData || []}
      value={value}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      itemComponent={SelectItem}
      onChange={handleChange}
      label={'Online Clients'}
      placeholder={'Select Online Clients'}
      searchable
      nothingFound={'Online client with given search query not found'}
      clearable
      allowDeselect
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) || false
      }
      maxDropdownHeight={160}
      miw={250}
      maw={330}
    />
  );
};

export default OnlineClients;
