import { toast } from 'react-toastify';
import parseDateTime from '../../../utils/getDateAndTime';
import styles from './Card.module.css';
import { baseRequestUrl } from '../../../constants/Default';
import { useNavigate } from 'react-router-dom';
import fileDataType from '../../../interface/data/FileData';

type CardProps = {
  center: string;
  title: string;
  date: string;
  id: string;
  index: number;
  data: any;
  document: fileDataType;
  isAllSchedulesData?: boolean;
  closeModal: () => void;
};

const Card: React.FC<CardProps> = ({
  center,
  title,
  date,
  id,
  index,
  closeModal,
  isAllSchedulesData = false,
  data,
  document,
}) => {
  const navigate = useNavigate();
  const { date: formattedDate, time } = parseDateTime(date.toString());

  console.log('Document data to see whether scheduled or not', document);
  // console.log('Old schedule data', data);

  const requestUrl = isAllSchedulesData
    ? `${baseRequestUrl}/${id}/select-schedule/${index}?all=true`
    : `${baseRequestUrl}/${id}/select-schedule/${index}`;

  const handleSelectClick = async () => {
    try {
      const response = await fetch(`${requestUrl}`, {
        method: 'PATCH',
        headers: {
          Authorization: localStorage.getItem('id_token') as string,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
        }),
      });

      const dataResponse = await response.json();
      console.log('Card.tsx - data', dataResponse);
      closeModal();
      toast.success('Schedule selected successfully');
    } catch (error) {
      toast.error('Error occurred in selecting the schedule');
      localStorage.clear();
      navigate('/login');
    }
    closeModal();
  };

  return (
    <div className={styles['card-container']}>
      <div className={styles['card-container-content-container']}>
        <div style={{ fontWeight: 'semi-bold' }}>{center}</div>
        <div className={styles['card-container-content-title']}>{title}</div>
        <div className={styles['card-container-content-time']}>
          {formattedDate}, {time}
        </div>
      </div>
      <div className={styles['card-container-button-container']}>
        <button
          className={styles['card-container-button']}
          onClick={handleSelectClick}
        >
          Select
        </button>
      </div>
    </div>
  );
};

export default Card;
