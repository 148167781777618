import React from 'react';

import fileData from '../../../interface/props/table/files';

import Lottie from 'react-lottie';
import animationData from '../../../assets/not-found-lottie.json';

type Props = {
  document: fileData;
};

const LateBy: React.FC<Props> = ({ document }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const delay =
    document.fileType === 'video'
      ? document.scheduleDetails
        ? Math.floor(
            (new Date(document.startTime).valueOf() -
              new Date(document.scheduleDetails.startTime).valueOf()) /
              60000
          )
        : 0
      : document.scheduleDetails
      ? Math.floor(
          (new Date(document.stopTime).valueOf() -
            new Date(document.scheduleDetails.endTime).valueOf()) /
            60000
        )
      : 0;

  const color = delay > 0 ? '#dc3c3d' : '#3d9b3d';

  return (
    <div style={{ color, fontWeight: 'bold', textAlign: 'center' }}>
      {delay ? (
        delay < 0 ? (
          `${Math.abs(delay)} min early`
        ) : (
          `${delay} min late`
        )
      ) : (
        <div style={{ zIndex: '-100' }}>
          <Lottie
            options={defaultOptions}
            height={100}
            width={100}
            zIndex={0}
          />
        </div>
      )}
    </div>
  );
};

export default LateBy;
