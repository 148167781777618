import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Modal, ScrollArea, Loader } from '@mantine/core';
import Card from './Card';

import styles from '../../filters/buttons/CommonStyling.module.css';
import fileDataType from '../../../interface/data/FileData';
import { baseRequestUrl } from '../../../constants/Default';
import { useNavigate } from 'react-router-dom';

import modalStyles from './Card.module.css';
import { toast } from 'react-toastify';
import Images from '../../../assets/images/images';

type Props = {
  title: string;
  document: fileDataType;
};

const Schedule: React.FC<Props> = ({ title, document }) => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);

  const [allScheduleData, setAllScheduleData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleScheduleButtonClick = (document: fileDataType) => {
    const fileId = document._id;
    const getAllScheduleData = async (fileId: string) => {
      try {
        setIsLoading(true);
        const response = await fetch(`${baseRequestUrl}/${fileId}/schedules`, {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('id_token') as string,
            // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*nFQXs',
          },
        });

        const data = await response.json();
        // console.log('Schedule.tsx - Schedules data', data);

        // Getting all the response in the [{}] format
        setAllScheduleData(data);
        setIsLoading(false);
      } catch (error) {
        toast.error('Error occurred in fetching all the schedules');
        localStorage.clear();
        navigate('/login');
      }
    };

    getAllScheduleData(fileId);

    open();
  };

  return (
    <>
      <Modal
        className={modalStyles['mantine-Modal-close']}
        opened={opened}
        onClose={close}
        title={
          <div
            style={{
              fontSize: '1.25rem',
              fontWeight: 'bold',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            Select a Schedule
          </div>
        }
        centered
        scrollAreaComponent={ScrollArea.Autosize}
      >
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Loader />
          </div>
        ) : document?.schedules?.length > 0 ? (
          document?.schedules?.map((data: any, index: number) => (
            <Card
              key={index}
              index={index}
              center={`${data.batchNameProp} - ${data.batchSubjectId.subjectId.name}`}
              title={`${data.topic ? data.topic : '-- No Title --'}`}
              date={new Date(data.startTime).toISOString()}
              closeModal={close}
              id={document._id}
              data={data}
              document={document}
            />
          ))
        ) : allScheduleData?.data?.allSchedules?.length > 0 ? (
          allScheduleData?.data?.allSchedules.map(
            (data: any, index: number) => (
              <Card
                key={index}
                index={index}
                closeModal={close}
                center={`${data.batchNameProp} - ${data.batchSubjectId.subjectId.name}`}
                title={`${data.topic ? data.topic : '-- No Title --'}`}
                date={new Date(data.startTime).toISOString()}
                id={document._id}
                isAllSchedulesData={true}
                data={data}
                document={document}
              />
            )
          )
        ) : (
          <div>No schedules found</div>
        )}
      </Modal>

      {/* <div> */}
      <img
        src={Images.search}
        alt='Find a schedule'
        onClick={() => handleScheduleButtonClick(document)}
      />
      {/* </div> */}
      {/* <button
        onClick={() => handleScheduleButtonClick(document)}
        className={styles['common-styling']}
      >
        {title} a Schedule
      </button> */}
    </>
  );
};

export default Schedule;
