const centerData = [
  { value: 'Noida', label: 'Noida' },
  { value: 'Bengaluru', label: 'Bengaluru' },
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Kota', label: 'Kota' },
  { value: 'Lucknow', label: 'Lucknow' },
  { value: 'Kolkata', label: 'Kolkata' },
];

const roomData = [
  { value: '101', label: '101' },
  { value: '102', label: '102' },
  { value: '103', label: '103' },
  { value: '204', label: '204' },
  { value: '205', label: '205' },
  { value: '206', label: '206' },
  { value: '307', label: '307' },
  { value: '308', label: '308' },
  { value: '309', label: '309' },
  { value: 'B1', label: 'B1' },
  { value: 'B2', label: 'B2' },
  { value: 'A1', label: 'A1' },
  { value: 'A2', label: 'A2' },
];

const fileTypeData = [
  { value: 'video', label: 'Video' },
  { value: 'demo', label: 'Demo' },
  { value: 'notes', label: 'Notes' },
];

export { centerData, roomData, fileTypeData };
