import React from 'react';

import styles from './Kpi.module.css';

import KpiProps from '../../../interface/props/header/Kpi';

const Kpi: React.FC<KpiProps> = ({ title, value }) => {
  const getClassName = (title: string) => {
    let className = styles['kpi-container'];
    // let className = "";

    switch (title) {
      case 'Not Uploaded':
        className += ` ${styles['not-uploaded']}`;
        break;

      case 'Not Uploaded %':
        className += ` ${styles['not-uploaded']}`;
        break;

      case 'Uploaded':
        className += ` ${styles['published']}`;
        break;

      case 'Uploaded %':
        className += ` ${styles['published']}`;
        break;

      case 'Scheduled':
        className += ` ${styles['published']}`;
        break;

      case 'Scheduled %':
        className += ` ${styles['published']}`;
        break;

      case 'Not Scheduled':
        className += ` ${styles['not-uploaded']}`;
        break;

      case 'Not Scheduled %':
        className += ` ${styles['not-uploaded']}`;
        break;
    }

    return className;
  };

  return (
    <div className={getClassName(title)}>
      <span className={styles['kpi-text']} style={{ textAlign: 'center' }}>
        {title} -{''}
      </span>
      <span>{value}</span>
    </div>
  );
};

export default Kpi;
