import React from 'react';

import styles from './CommonStyling.module.css';

import { useDispatch } from 'react-redux';
import { handleReset } from '../../../features/filters/filterSlice';

const Reset = () => {
  const dispatch = useDispatch();

  return (
    <button
      className={styles['common-styling']}
      onClick={() => dispatch(handleReset(true))}
    >
      Reset Filter
    </button>
  );
};

export default Reset;
