import { useState, useEffect } from 'react';

import fileDataType from '../interface/data/FileData';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading } from '../features/data/fileDataSlice';

// React Router Dom
import { useNavigate } from 'react-router-dom';

// React Toastify
import { toast } from 'react-toastify';

// Constants
import { baseRequestUrl } from '../constants/Default';
import { RootState } from '../app/store';

type FetchFileDataHookResult = {
  fileData: fileDataType[] | null;
  setFileData: React.Dispatch<React.SetStateAction<fileDataType[] | null>>;
};

type FetchFileDataHook = (
  center: string[] | null,
  room: string | null,
  from: string | null,
  to: string | null,
  fileType: string | null,
  batchId: string | null,
  uploaded: number,
  scheduled: number
) => FetchFileDataHookResult;

const useFetchFileData: FetchFileDataHook = (
  center,
  room,
  from,
  to,
  fileType,
  batchId,
  uploaded,
  scheduled
) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [fileData, setFileData] = useState<fileDataType[] | null>(null);
  const youtubeId = useSelector((state: RootState) => state.filter.youtubeId);

  // console.log(center, room, from, to, fileType);

  useEffect(() => {
    const getFileData = async (
      center: string[] | null,
      room: string | null,
      from: string | null,
      to: string | null,
      fileType: string | null,
      uploaded: number,
      scheduled: number,
      youtubeId: string | null
    ) => {
      // Creating the query string
      let queryString: string = '';

      // // Adding the from and to time to the query string
      if (from !== null && to !== null) {
        // Extracting the Date and Time from the string
        queryString += `from=${from}&to=${to}`;
      }

      // Adding the center and room to the query string
      if (center?.length !== 0) {
        queryString += `&centers=${JSON.stringify(center)}`;
      }

      if (room) {
        queryString += `&room=${room}`;
      }

      // Adding the file type to the query string
      if (fileType) {
        queryString += `&type=${fileType}`;
      }

      if (uploaded!==0){
        queryString += `&uploaded=${uploaded}`
      } 

      if (scheduled!==0){
        queryString += `&scheduled=${scheduled}`
      } 

      if (batchId) {
        queryString += `&batchId=${batchId}`;
      }

      // Final query string
      let finalQueryString: string;

      finalQueryString = `${baseRequestUrl}?${queryString}`;
      if(youtubeId){
        finalQueryString = `${baseRequestUrl}/youtube/${youtubeId}`;
      }

      try {
        dispatch(setIsLoading(true));

        const response = await fetch(finalQueryString, {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('id_token') as string,
            // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*FQXs',
          },
        });
        const data = await response.json();
        if(youtubeId){
          console.log('Youtube Data: ', data);
          if(data.data){
            setFileData([data.data]);
          }else{
            setFileData([]);
          }
        }else{
          setFileData(data.data);
        }
      } catch (error) {
        toast.error('Error in fetching data');
        localStorage.clear();
        navigate('/login');
      }
      // Getting all the response in the [{}] format
    };

    getFileData(center, room, from, to, fileType, uploaded, scheduled, youtubeId);
  }, [center, room, from, to, fileType, batchId, uploaded, scheduled,youtubeId, dispatch, navigate]);

  return { fileData, setFileData };
};

export default useFetchFileData;
