import React from 'react';

import fileData from '../../../interface/props/table/files';
import Images from '../../../assets/images/images';

import styles from './FileType.module.css';
import capitalizeFirstLetter from '../../../utils/firstLetterCapital';

type Props = {
  document: fileData;
};

const FileType: React.FC<Props> = ({ document }) => {
  const fileType = document.fileType;
  const fileIcon =
    fileType === 'notes'
      ? Images.doc
      : fileType === 'video'
      ? Images.video
      : Images.demo;

  return (
    <div className={styles['file-type-container']}>
      {' '}
      <img src={fileIcon} alt={fileType} />
      <span>{capitalizeFirstLetter(fileType)}</span>
    </div>
  );
};

export default FileType;
