import demo from './demo.png';
import doc from './doc.png';
import edit from './edit.png';
import link from './link.png';
import reload from './reload.png';
import video from './video.png';
import search from './search.png';
import preview from './preview.png';

const Images = {
  demo,
  doc,
  edit,
  link,
  reload,
  video,
  search,
  preview,
};

export default Images;
