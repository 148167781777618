import React from 'react';
import './App.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Tracker from './pages/Tracker';
import Login from './pages/Login';
import { RetryAdmin } from './pages/Retry-Admin';

function App() {
  return (
    <BrowserRouter>
      <div>
        <ToastContainer
          position='top-center'
          autoClose={1200}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light'
        />

        <Routes>
          <Route path='/' element={<Tracker />} />
          <Route path='/login' element={<Login />} />
          <Route path='/retry-admin' element={<RetryAdmin />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
