import React from 'react';

import fileData from '../../../interface/props/table/files';
import Schedule from '../../modal/Schedule/Schedule';

import styles from './ThumbNail.module.css';

type Props = {
  document: fileData;
};

const ThumbNail: React.FC<Props> = ({ document }) => {
  const youtubeId = document.youtubeId;

  return (
    <div className={styles['thumbnail-container']}>
      {youtubeId ? (
        <img
          alt={document.fileName}
          src={`https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`}
        />
      ) : (
        <div className={styles['thumbnail-container-schedule']}>
          {document.schedules ? (
            document.fileType === 'notes' && document.schedules?.length>0 ? (
              <div>Notes scheduled</div>
            ) : (
              <div>Found {document.schedules?.length} schedules</div>
            )
            // <div>Found {document.schedules?.length} schedules</div>
          ) : (
            <div>Unable to publish the video </div>
          )}
          {/* {document.schedules?.length !== 0 && !document.scheduled && (
            <Schedule document={document} title='Select' />
          )}
          {document.schedules?.length === 0 && !document.scheduled && (
            <Schedule document={document} title='Find' />
          )} */}
        </div>
      )}
    </div>
  );
};

export default ThumbNail;
