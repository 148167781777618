import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type PingSelect = {
  label: string;
  value: string;
};

type PingInitialState = {
  pingData: PingSelect[] | null;
};

const initialState: PingInitialState = {
  pingData: null,
};

const pingSlice = createSlice({
  name: 'pingSlice',
  initialState,
  reducers: {
    setPingData: (state, action: PayloadAction<PingSelect[] | null>) => {
      state.pingData = action.payload;
    },
  },
});

export const { setPingData } = pingSlice.actions;

export default pingSlice.reducer;
