import React from 'react';

import fileData from '../../../interface/props/table/files';

import styles from './Center.module.css';

type Props = {
  document: fileData;
};

const CenterRoom: React.FC<Props> = ({ document }) => {
  return (
    <div className={styles['center-container']}>
      <div className={styles['center-container-room']}>
        {document.center} - {document.room}
      </div>

      <div className={styles['center-container-time']}>
        Time:{' '}
        {String(new Date(document.startTime).toLocaleString()).split(', ')[1]} -{' '}
        {String(new Date(document.stopTime).toLocaleString()).split(', ')[1]}
      </div>
    </div>
  );
};

export default CenterRoom;
