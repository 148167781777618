import { useState } from "react"
import axios from 'axios';

export function RetryAdmin() {
    const [fileId, setFileId] = useState("");

    function retryUpload() {
        axios.patch(`https://studio-app-api.penpencil.co/v1/studio/retry-upload/${fileId}`)
        .then(() => {
            alert("Successful!")
        })
        .catch(() => {
            alert("Something went wrong!")
        })
    }

    return(
        <div style={{ display: 'grid', placeItems: 'center', height: '100vh', width: '100vw', justifyItems: 'center', alignContent: 'center', gap: '1em' }} >
            <input type="text" onChange={(e) => { setFileId(e.target.value) }} style={{ width: "15em" }} placeholder="Enter file ID" />
            <button style={{ width: "10em" }} onClick={retryUpload} >Retry-Upload</button>
        </div>
    )
}