import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import fileDataType from '../../../interface/data/FileData';
import { Modal } from '@mantine/core';

type Props = {
  opened: boolean;
  title: string;
  document: fileDataType;
  close: () => void;
  open?: () => void;
};

const PreviewModal = ({ opened, title, document, close, open }: Props) => {
  const [previewLink, setPreviewLink] = useState<string | null>(null);
  const navigate = useNavigate();
  const fileType = document.fileType;
  const uploaded = document.uploaded;
  const youtubeId = document?.youtubeId;
  const _id = document._id;

  useEffect(() => {
    const fetchLink = async () => {
      const link = youtubeId
        ? `https://www.youtube.com/watch?v=${youtubeId}`
        : `https://studio-app-api.penpencil.co/v1/studio/files/signed-url/${_id}`;

      let copyUrl = link;

      try {
        if (youtubeId) {
          copyUrl = link;
        } else {
          const response = await fetch(link, {
            method: 'GET',
            headers: {
              Authorization: localStorage.getItem('id_token') as string,
              // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*FQXs',
            },
          });

          const data = await response.json();
          copyUrl = data?.data.publicUrl;
        }
        setPreviewLink(copyUrl);
        // navigator.clipboard.writeText(copyUrl);
        // toast.success('Preview Link Found');
      } catch (error) {
        // toast.error('Preview Link Not Found');
        localStorage.clear();
        navigate('/login');
      }
    };

    fetchLink();
  }, [_id, navigate, youtubeId]);

  // console.log('Preview Link: ', previewLink);

  return (
    <>
      <Modal title={title} size='auto' opened={opened} centered onClose={close}>
        {fileType === 'video' && uploaded && youtubeId && (
          <iframe
            width='560'
            height='315'
            src={`https://www.youtube.com/embed/${document.youtubeId}`}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          />
        )}
        {fileType === 'video' && uploaded && !youtubeId && (
          <video width='320' height='240' controls>
            {previewLink && <source src={`${previewLink}`} />}
            Your browser does not support the video tag.
          </video>
        )}
      </Modal>
    </>
  );
};

export default PreviewModal;
