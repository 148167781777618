import { useState, ChangeEvent } from 'react';

// React Toastify
import { toast } from 'react-toastify';

// React Router Dom
import { useNavigate } from 'react-router-dom';

// Styles
import styles from './UploadThumbnail.module.css';

// Utils
import { baseRequestUrl } from '../../../../constants/Default';
import fileDataType from '../../../../interface/data/FileData';

type Props = {
  fileId: string;
  fileData: fileDataType | null;
  closeModal: () => void;
};

const UploadThumbnail = ({ fileId, fileData, closeModal }: Props) => {
  // React Router Dom
  const navigate = useNavigate();

  const [thumbnail, setThumbnail] = useState<string | null>(null);

  // Getting the youtubeID
  const youtubeId = fileData?.youtubeId || '';

  // Function to handle the thumbnail change
  const handleThumbnailChange = (event: ChangeEvent<HTMLInputElement>) => {
    // Gives the information about the files
    const file = event.target.files?.[0];

    if (file) {
      // To read the selected file
      const reader = new FileReader();

      reader.onload = (event) => {
        // Setting the preview to the base64Url of the selected image
        setThumbnail(event.target?.result as string);
      };

      // This will trigger the onload function above
      reader.readAsDataURL(file);
    }
  };

  // Dummy function to simulate uploading the thumbnail
  const handleUpload = async (youtubeId: string) => {
    try {
      const response = await fetch(
        `${baseRequestUrl}/${fileId}/edit-thumbnail`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: localStorage.getItem('id_token') as string,
          },
          body: JSON.stringify({ thumbnailBase64: thumbnail, youtubeId }),
        }
      );

      const data = await response.json();

      closeModal();

      if (response.ok) {
        toast.success('Check back in 15 minutes');
        console.log('Thumbnail data from server: ', data);
      } else {
        toast.error('Error uploading thumbnail');
      }
    } catch (error) {
      localStorage.clear();
      navigate('/login');
      console.error('Error uploading thumbnail', error);
    }
  };

  return (
    <div className={styles['upload-thumbnail-container']}>
      {thumbnail && (
        <div className={styles['thumbnail-preview']}>
          <img
            src={thumbnail}
            alt='Thumbnail Preview'
            className={styles['thumbnail-preview-image']}
          />
        </div>
      )}
      <input
        type='file'
        accept='image/*'
        onChange={handleThumbnailChange}
        id='thumbnail-input'
        style={{ display: 'none' }} // Hide the default input element
      />
      <label htmlFor='thumbnail-input' className={styles['upload-button']}>
        {thumbnail ? 'Change Thumbnail' : 'Upload Thumbnail'}
      </label>
      {thumbnail && (
        <button
          onClick={() => handleUpload(youtubeId)}
          className={styles['save-thumbnail-button']}
        >
          Save Thumbnail
        </button>
      )}
    </div>
  );
};

export default UploadThumbnail;
