type Props = {
  status: boolean;
};

const Dot: React.FC<Props> = (props) => {
  const { status } = props;
  const color = status ? '#12b76a' : '#dc3c3d';
  return (
    <div
      style={{
        width: '1rem',
        height: '1rem',
        borderRadius: '50%',
        backgroundColor: `${color}`,
      }}
    ></div>
  );
};

export default Dot;
