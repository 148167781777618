/**
 * @description -  Remove duplicates from array of objects
 * @param arr
 * @param prop
 * @returns [] - array of objects without duplicates
 */
const removeDuplicates = (arr: any[], prop: string): any[] => {
  return arr.filter(
    (obj, index, self) => index === self.findIndex((o) => o[prop] === obj[prop])
  );
};

export default removeDuplicates;
