import React from 'react';

import Actions from '.././action/Actions';
import CenterRoom from '.././center/CenterRoom';
import Date from '.././date/Date';
import FileName from '.././file/FileName';
import FileType from '.././file/FileType';
import Status from '.././status/Status';
import LateBy from '.././status/LateBy';
import ThumbNail from '../thumbnail/ThumbNail';

import fileData from '../../../interface/props/table/files';

type Props = {
  document: fileData;
};

const Row: React.FC<Props> = ({ document }) => {
  return (
    <tr>
      <td>
        <CenterRoom document={document} />
      </td>
      <td>
        <FileName document={document} />
      </td>
      <td>
        <Date document={document} />
      </td>
      <td>
        <FileType document={document} />
      </td>
      <td>
        <Status document={document} />
      </td>
      <td>
        <LateBy document={document} />
      </td>
      <td>
        <ThumbNail document={document} />
      </td>
      <td>
        <Actions document={document} />
      </td>
    </tr>
  );
};

export default Row;
