import React from 'react';

import Kpi from './kpi/Kpi';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../app/store';

// Hooks
import useFetchVideoStats from '../../hooks/useFetchVideoStats';

// Styles
import styles from './index.module.css';
import { setScheduled, setUploaded } from '../../features/filters/filterSlice';

const Header = () => {
  const { from, to, center, uploaded, scheduled } = useSelector((state: RootState) => state.filter);

  const { statsData } = useFetchVideoStats(from, to, center);

  // console.log('Stats Datas: ', statsData);

  // Only the uploaded video
  const uploadVideoCount = statsData?.uploadedFilesCount ?? 0;
  // Only the not uploaded video
  const notUploadedVideoCount = statsData?.notUploadedFilesCount ?? 0;
  // Only the scheduled video
  const scheduleVideoCount = statsData?.scheduledFilesCount ?? 0;

  const totalVideoCount =
    uploadVideoCount + scheduleVideoCount + notUploadedVideoCount;

  const notScheduleVideoCount = totalVideoCount - scheduleVideoCount - notUploadedVideoCount;

  const totalUploadedVideoCount = uploadVideoCount + scheduleVideoCount;

  const notUploadedPercentage = notUploadedVideoCount
    ? ((notUploadedVideoCount / totalVideoCount) * 100).toFixed(2)
    : 0;

  const uploadedPercentage = totalUploadedVideoCount
    ? ((totalUploadedVideoCount / totalVideoCount) * 100).toFixed(2)
    : 0;

  const scheduledPercentage = scheduleVideoCount
    ? ((scheduleVideoCount / totalUploadedVideoCount) * 100).toFixed(2)
    : 0;

  const notScheduledPercentage = notScheduleVideoCount
  ? ((notScheduleVideoCount / totalUploadedVideoCount) * 100).toFixed(2)
  : 0;

  const dispatch = useDispatch();
  
  return (
    <div className={styles['header-container'] + " " + styles['noscr']}>
      <div className={styles['kpi-container']}>
        <button className={styles['button-holder']} ><Kpi title={'Total'} value={totalVideoCount}/></button>
        <button className={styles['button-holder']+ " " + ( uploaded===-1 ? styles['red-active']: styles['red-inactive'])} onClick={()=>{dispatch(setUploaded( uploaded===-1? 0:-1))}}><Kpi title={'Not Uploaded'} value={`${notUploadedVideoCount} (${notUploadedPercentage}%)`} /></button>
        {/* <button className={styles['button-holder']+ " " + ( uploaded===-1 ? styles['red-active']: styles['red-inactive'])} onClick={()=>{dispatch(setUploaded(uploaded===-1? 0:-1))}}><Kpi title={'Not Uploaded %'} value={notUploadedPercentage} /></button> */}
        <button className={styles['button-holder']+ " " + ( uploaded===1 ? styles['green-active']: styles['green-inactive'])} onClick={()=>{dispatch(setUploaded(uploaded===1? 0:1))}}><Kpi title={'Uploaded'} value={`${totalUploadedVideoCount} (${uploadedPercentage}%)`} /></button>
        {/* <button className={styles['button-holder']+ " " + ( uploaded===1 ? styles['green-active']: styles['green-inactive'])} onClick={()=>{dispatch(setUploaded(uploaded===1? 0:1))}}><Kpi title={'Uploaded %'} value={uploadedPercentage} /></button> */}
        <button className={styles['button-holder']+ " " + ( scheduled===-1 ? styles['red-active']: styles['red-inactive'])} onClick={()=>{dispatch(setScheduled(scheduled===-1? 0:-1))}}><Kpi title={'Not Scheduled'} value={`${notScheduleVideoCount} (${notScheduledPercentage}%)`} /></button>
        {/* <button className={styles['button-holder']+ " " + ( scheduled===-1 ? styles['red-active']: styles['red-inactive'])} onClick={()=>{dispatch(setScheduled(scheduled===-1? 0:-1))}}><Kpi title={'Not Scheduled %'} value={notScheduledPercentage} /></button> */}
        <button className={styles['button-holder']+ " " + ( scheduled===1 ? styles['green-active']: styles['green-inactive'])} onClick={()=>{dispatch(setScheduled(scheduled===1? 0:1))}}><Kpi title={'Scheduled'} value={`${scheduleVideoCount} (${scheduledPercentage}%)`} /></button>
        {/* <button className={styles['button-holder']+ " " + ( scheduled===1 ? styles['green-active']: styles['green-inactive'])} onClick={()=>{dispatch(setScheduled(scheduled===1? 0:1))}}><Kpi title={'Scheduled %'} value={scheduledPercentage} /></button> */}
      </div>
    </div>
  );
};

export default Header;
