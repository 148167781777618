const capitalizeFirstLetter = (str: string): string => {
  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  if (str.length === 0) {
    return str;
  }

  const firstLetter = str[0].toUpperCase();
  const restOfString = str.slice(1);

  return firstLetter + restOfString;
};

export default capitalizeFirstLetter;
