import { useEffect } from 'react';

import DataTable from '../components/table';
import Filters from '../components/filters';
import LoadMore from '../components/buttons/LoadMore';
import { useNavigate } from 'react-router-dom';

import Header from '../components/header';

import { useSelector } from 'react-redux';
import type { RootState } from '../app/store';
import useFetchPingData from '../hooks/useFetchPingData';

const Tracker = () => {
  const navigate = useNavigate();

  const { isLoading } = useSelector((state: RootState) => state.fileData);

  // https://stackoverflow.com/questions/45561610/how-can-i-remove-local-storage-after-one-hour
  useEffect(() => {
    if (!localStorage.getItem('id_token')) {
      navigate('/login');
    } else if (localStorage.getItem('expiry_time')) {
      const expiryTime = parseInt(localStorage.getItem('expiry_time') ?? '0');
      if (new Date().getTime() > expiryTime) {
        localStorage.clear();
        navigate('/login');
      }
    }
  });

  useFetchPingData();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem',
          justifyContent: 'center',
        }}
      >
        <Header />
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            gap: '0.5rem',
          }}
        >
          <Filters />
        </div>
      </div>
      <hr
        style={{
          backgroundColor: '#eaecef',
          height: '0.5rem',
          width: '100%',
          border: 'none',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem',
          justifyContent: 'center',
        }}
      >
        <DataTable />
        {!isLoading && <LoadMore />}
      </div>
    </div>
  );
};

export default Tracker;
