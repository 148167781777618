import { TextInput } from '@mantine/core'
import { useDispatch, useSelector } from 'react-redux';
import { setYoutubeId } from '../../../features/filters/filterSlice';
import { RootState } from '../../../app/store';

function YoutubeIdFilter() {

  const dispatch = useDispatch();
  const value = useSelector((state: RootState) => state.filter.youtubeId);

  const handleChange = (val: string) => {
    console.log("youtubeId",val)
    dispatch(setYoutubeId(val));
  };

  return (
    <div>
      <TextInput
        value={value}
        onChange={(e) => handleChange(e.currentTarget.value)}
        label="Youtube ID"
        placeholder="Enter Video Id"
        radius="sm"
        id="youtubeId"
        name="youtubeId"
      />
    </div>
  )
}

export default YoutubeIdFilter