import { useEffect, useState } from 'react';

// Components
import Header from './header/Header';
import Row from './row/Row';

// Mantine
import { Loader } from '@mantine/core';

// Styles
import styles from './DataTable.module.css';

// Redux
import type { RootState } from '../../app/store';
import { useSelector, useDispatch } from 'react-redux';
import { setFileData, setIsLoading } from '../../features/data/fileDataSlice';

// Hooks
import useFetchFileData from '../../hooks/useFetchAllData';

// Types
import fileDataType from '../../interface/data/FileData';

// Utils
import removeDuplicates from '../../utils/removeDuplicates';

// Lottie
import Lottie from 'react-lottie';
import animationData from '../../assets/no-data.json';

const DataTable = () => {
  const [newDataFinal, setNewDataFinal] = useState<fileDataType[]>([]);

  // Redux
  const dispatch = useDispatch();
  const filterState = useSelector((state: RootState) => state.filter);

  const { center, room, from, to, fileType, batchId, uploaded, scheduled } = filterState;

  console.log("Filter's State: ", filterState);

  // To get the data from the store
  const { data, isLoading } = useSelector((state: RootState) => state.fileData);

  // To fetch the data from the backend
  const { fileData } = useFetchFileData(
    center,
    room,
    from,
    to,
    fileType,
    batchId,
    uploaded,
    scheduled
  );

  // Options for the Lottie animation
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // To remove the duplicate data based on the unique id
  useEffect(() => {
    const newDataFinal = removeDuplicates(data, '_id');
    setNewDataFinal(newDataFinal);
  }, [data]);

  // To set the data in the store
  useEffect(() => {
    dispatch(setIsLoading(true));
    // Setting the data in the store
    dispatch(setFileData(fileData || []));
    dispatch(setIsLoading(false));
  }, [fileData, dispatch]);

  return isLoading ? (
    <div>
      <Loader className={styles['loader']} variant='dots' size='md' />
    </div>
  ) : newDataFinal?.length !== 0 ? (
    <table className={styles.table}>
      <thead>
        <Header />
      </thead>
      <tbody>
        {newDataFinal?.map((document: fileDataType, index: number) => (
          <Row key={index} document={document} />
        ))}
      </tbody>
    </table>
  ) : (
    <Lottie options={defaultOptions} width={'100%'} zIndex={0} />
  );
};

export default DataTable;
