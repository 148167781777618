import React from 'react';

import styles from './Header.module.css';

const Header = () => {
  return (
    <tr>
      <th style={{ width: '16rem' }}>
        <div className={styles.center}>
          <span>Center - Room</span>
          <span className={styles['center-run-time']}>Class Run Time</span>
        </div>
      </th>
      <th style={{ width: '15rem' }}>File Name</th>
      <th style={{ width: '5rem' }}>Date</th>
      <th style={{ width: '5rem' }}>File Type</th>
      <th style={{ width: '5rem' }}>
        <div className={styles.status}>
          <div>Status</div>
          <div className={styles['status-ups']}>
            <div>U</div>
            {/* <div>P</div> */}
            <div>S</div>
          </div>
        </div>
      </th>
      <th style={{ width: '5rem', zIndex: '10' }}>Late By</th>
      <th style={{ width: '8rem' }}>Thumbnail</th>
      <th style={{ width: '8rem' }}>Action</th>
    </tr>
  );
};

export default Header;
