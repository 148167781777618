import { useMemo, useState, useEffect, forwardRef } from 'react';

// Mantine
import { Select } from '@mantine/core';
import type { SelectItemProps } from '@mantine/core';

// Redux
import type { RootState } from '../../../app/store';
import { useSelector, useDispatch } from 'react-redux';
import { setBatchId } from '../../../features/filters/filterSlice';

type Props = {
  batchSelectComponentData: BatchSelectComponentType[] | null;
};

const BatchIdFilter = ({ batchSelectComponentData }: Props) => {
  // To dispatch the action
  const dispatch = useDispatch();

  // To get the filter data from the store
  const isResetState = useSelector((state: RootState) => state.filter.isReset);
  const { center, batchId } = useSelector((state: RootState) => state.filter);

  // To get the isReset value from the store and use it in the useMemo hook
  const isReset = useMemo(() => {
    return isResetState;
  }, [isResetState]);

  // Current filter value
  const [value, setValue] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  // To make sure that we reset the value if there is a reset
  useEffect(() => {
    setValue(batchId);
    if (isReset) {
      setValue(null);
      setSearchValue('');
    }
  }, [isReset, batchId]);

  const handleChange = (val: string) => {
    // console.log('Value: ', val, 'Type of Value : ', typeof val);
    setValue(val);
    // Set the batch id in the store
    dispatch(setBatchId(val));
  };

  const handleSearchChange = (val: any) => {
    // console.log('Value : ', val, 'Type of Value: ', typeof val);
    setSearchValue(val);
  };

  // Used in the Select component of the Mantine Dev
  const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ label, ...others }: SelectItemProps, ref) => (
      <div ref={ref} {...others}>
        <div>{label}</div>
      </div>
    )
  );

  console.log('Batch Select Component Data: ', batchSelectComponentData);

  return (
    <Select
      data={batchSelectComponentData || []}
      value={value}
      disabled={center.length === 0 ? true : false}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      itemComponent={SelectItem}
      onChange={handleChange}
      label={'Select Batch Name'}
      placeholder={'Select any batch you want'}
      searchable
      nothingFound={'No batch found with given search query'}
      clearable
      allowDeselect
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) || false
      }
      maxDropdownHeight={160}
      maw={180}
    />
  );
};

export default BatchIdFilter;
