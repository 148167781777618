import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

import {
  FacultyDataSheets,
  FacultyData,
  Faculty,
} from '../interface/data/FacultyData';

/**
 * @description - Custom Hook to fetch the data of all the faculty members grouped by centers
 * @returns - {allFacultyData, setAllFacultyData}
 */
const useFetchAllFaculty = () => {
  const navigate = useNavigate();
  const requestUrl =
    'https://sheets.googleapis.com/v4/spreadsheets/1OTnzm9gKVce4edD4qWZzeemvmjQknZbon5rLbc8xtAE/values/Teachers!A:E?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ';

  const [allFacultyData, setAllFacultyData] = useState<FacultyData[] | null>(
    null
  );

  useEffect(() => {
    const getFacultyData = async () => {
      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
        });

        const data: FacultyDataSheets = await response.json();

        // Getting the original data that we need to use
        const sheetData = data.values;

        // Removing the headers from the sheet data
        sheetData?.shift();

        // Getting the data in the form [{center: string, faculty: [{name: string, email: string, driveId: string}]}]
        const outputData: FacultyData[] = [];

        sheetData?.forEach((facultyData) => {
          const center = facultyData[0];

          const name = facultyData[1];
          const email = facultyData[2];
          const driveId = facultyData[4];

          const faculty: Faculty = { name, email, driveId };

          // Now we have to check whether the center exsists in the outputData or not
          const exsistingCenter = outputData.find(
            (data) => data.center === center
          );

          if (exsistingCenter) {
            exsistingCenter.faculty.push({ ...faculty });
          } else {
            outputData.push({
              center,
              faculty: [{ ...faculty }],
            });
          }
        });

        setAllFacultyData(outputData);
      } catch (error) {
        toast.error('Error in fetching the Faculty data');
        localStorage.clear();
        navigate('/login');
      }
    };

    getFacultyData();
  }, [navigate]);

  return { allFacultyData, setAllFacultyData };
};

export default useFetchAllFaculty;
