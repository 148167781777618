import { useState, useEffect } from 'react';

import { CenterDataSheets, CenterData } from '../interface/data/CenterData';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

const useFetchCentersData = () => {
  const navigate = useNavigate();

  const pw_master_sheet =
    'https://sheets.googleapis.com/v4/spreadsheets/1OTnzm9gKVce4edD4qWZzeemvmjQknZbon5rLbc8xtAE/values/Master!A:D?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ';
  const dummy_master_sheet =
    'https://sheets.googleapis.com/v4/spreadsheets/1ZkcIR04P7gQu0aBziwDBMfkkQFh94ERry60uvGJD1Ds/values/master!A:D?key=AIzaSyBxt0KCuDepK1R2b4SKj68nDFepMoSjVko';

  const requestUrl = dummy_master_sheet;

  const [centersData, setCentersData] = useState<CenterData[] | null>(null);

  useEffect(() => {
    const getCentersData = async () => {
      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
        });

        const data: CenterDataSheets = await response.json();

        const sheetData = data.values;

        // Removing the headers from the sheet data
        sheetData?.shift();

        const outputData: CenterData[] = [];

        // Now we have to club the data of the same center in the [{center: string, rooms: []}]
        sheetData?.forEach((centerData) => {
          const center = centerData[0];
          const room = centerData[1];
          const batchName = centerData[2];
          const batchId = centerData[3];

          // Now we have to check whether the center exsists in the outputData or not
          const exsistingCenter = outputData.find(
            (data) => data.center === center
          );

          if (exsistingCenter) {
            // Add room to exsisting center rooms
            exsistingCenter.rooms.push(room);
            exsistingCenter.batches.push({ batchId, batchName });
          } else {
            // Add a new center to the outputData
            outputData.push({
              center,
              rooms: [room],
              batches: [{ batchId, batchName }],
            });
          }
        });

        // console.log('All center room batch data: ', outputData);

        setCentersData(outputData);
      } catch (error) {
        toast.error('Error while fetching the centers data');
        localStorage.clear();
        navigate('/login');
      }
    };

    getCentersData();
  }, [navigate, requestUrl]);

  return { centersData, setCentersData };
};

export default useFetchCentersData;
