import { ChangeEvent, useState, useEffect, FormEvent, forwardRef } from 'react';

// Mantine
import { Select } from '@mantine/core';
import type { SelectItemProps } from '@mantine/core';

// Redux
import { useSelector } from 'react-redux';
import type { RootState } from '../../../../app/store';

// React - toastify
import { toast } from 'react-toastify';

// React Router Dom
import { useNavigate } from 'react-router-dom';

// Utils
import { baseRequestUrl } from '../../../../constants/Default';

// Types
import type fileDataType from '../../../../interface/data/FileData';

// Styles
import styles from './UpdateDetails.module.css';

type Props = {
  close: () => void;
  document: fileDataType;
  centersFaculty: {
    group: string;
    label: string;
    value: string;
  }[];
};

const UpdateDetails = ({ close, document, centersFaculty }: Props) => {
  const navigate = useNavigate();
  const { idNameFacultyData } = useSelector(
    (state: RootState) => state.faculty
  );

  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const topic = document?.scheduleDetails?.isSingleScheduling ? document?.scheduleDetails?.lecture?.topic : document?.scheduleDetails?.topic;

  useEffect(() => {
    setInputValue(topic || '');
  }, [topic]);

  const handleChange = (val: string) => {
    // console.log('Value: ', val, 'Type of Value : ', typeof val);
    setValue(val);
  };

  const handleSearchChange = (val: any) => {
    // console.log('Value : ', val, 'Type of Value: ', typeof val);
    setSearchValue(val);
  };

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event?.target.value);
  };

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>,
    facultyName: string,
    document: fileDataType
  ) => {
    event.preventDefault();

    // console.log('Faculty Name: ', facultyName);
    const value = idNameFacultyData?.find((item) => item.name === facultyName);
    // console.log('Faculty Details: ', value);

    const title = inputValue;
    const teacherDriveId = value?.driveId;
    const youtubeId = document?.youtubeId;

    const editDetails = async () => {
      try {
        const response = await fetch(
          `${baseRequestUrl}/${document._id}/edit-schedule`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('id_token') as string,
              // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*FQXs',
            },
            body: JSON.stringify({
              title,
              teacherDriveId,
              youtubeId,
            }),
          }
        );

        const data = await response.json();
        console.log('Edit Details Response: ', data);

        close();

        toast.success('Check again in 15 minutes');
      } catch (error) {
        toast.error('Something went wrong');
        localStorage.clear();
        navigate('/login');
      }
    };

    editDetails();

    // console.log('Faculty ', value);
    // console.log('Input Value: ', inputValue);
  };

  const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ label, ...others }: SelectItemProps, ref) => (
      <div ref={ref} {...others}>
        <div>{label}</div>
      </div>
    )
  );

  return (
    <form
      onSubmit={(event) => handleSubmit(event, value as string, document)}
      className={styles['edit-details-container']}
    >
      <div className={styles['details-container']}>
        <label htmlFor='file-name'>File Name</label>
        <textarea
          id='file-name'
          rows={4}
          placeholder='Enter the topic name'
          value={inputValue}
          onChange={handleInputChange}
          className={styles['edit-details-title']}
        />
      </div>

      <Select
        data={centersFaculty}
        itemComponent={SelectItem}
        value={value}
        key={value}
        searchValue={searchValue}
        onSearchChange={handleSearchChange}
        onChange={handleChange}
        dropdownPosition='bottom'
        label='Select the Faculty'
        placeholder='Select Teacher who took lecture'
        searchable
        nothingFound='No teacher found with the given search query'
        clearable
        allowDeselect
        filter={(value, item) =>
          item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
          false
        }
        maxDropdownHeight={130}
        miw={450}
        maw={450}
      />
      <button
        type='submit'
        className={styles['submit-button']}
        style={{ width: '100%' }}
      >
        Save
      </button>
    </form>
  );
};

export default UpdateDetails;
