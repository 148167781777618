import { useState } from 'react';

import fileData from '../../../interface/props/table/files';
import Images from '../../../assets/images/images';
import { useDisclosure } from '@mantine/hooks';
import EditDetails from '../../modal/EditDetails/EditDetails';
import { Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import Schedule from '../../modal/Schedule/Schedule';
import PreviewModal from '../../modal/Preview/PreviewModal';

import type {
  FacultyData,
  Faculty,
  FacultyDataSheets,
} from '../../../interface/data/FacultyData';

import styles from './Actions.module.css';
import { toast } from 'react-toastify';

type Props = {
  document: fileData;
};

type CentersFaculty = {
  group: string;
  label: string;
  value: string;
  key: string;
};

const reloadRequestUrl = `https://studio-app-api.penpencil.co/v1/studio/upload`;

const Actions: React.FC<Props> = ({ document }) => {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [
    isPreviewModalOpen,
    { open: openPreviewModal, close: closePreviewModal },
  ] = useDisclosure(false);

  // const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const [centersFaculty, setCentersFaculty] = useState<CentersFaculty[]>([]);

  // console.log('Document - Actions.tsx', document);

  const openInNewTab = (url) => {
    // window.open(url, '_blank', 'noopener,noreferrer');
    const win = window.open(url, '_blank');
    win?.focus();
  };

  const handleLinkClick = async (document: fileData) => {
    const link = document?.youtubeId
      ? `https://www.youtube.com/watch?v=${document?.youtubeId}`
      : `https://studio-app-api.penpencil.co/v1/studio/files/signed-url/${document._id}`;

    let copyUrl = link;

    try {
      if (document?.youtubeId) {
        copyUrl = link;
      } else {
        const response = await fetch(link, {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('id_token') as string,
            // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*FQXs',
          },
        });

        const data = await response.json();
        copyUrl = data?.data.publicUrl;
      }
      navigator.clipboard.writeText(copyUrl);
      toast.success('Link copied to clipboard');
    } catch (error) {
      toast.error('Something went wrong - Link cant be copied');
      localStorage.clear();
      navigate('/login');
    }

    // To open the link in new tab
    openInNewTab(copyUrl);
    // console.log('Link copied to clipboard', copyUrl);
  };

  const handleReloadClick = async (document: fileData) => {
    if (document.scheduled) {
      toast.error('Already scheduled, hence not re-scheduling it again');
      return;
    }

    try {
      const response = await fetch(`${reloadRequestUrl}/${document._id}`, {
        method: 'PATCH',
        headers: {
          Authorization: localStorage.getItem('id_token') as string,
          'Content-Type': 'application/json',
          // secret: 'Y9ecx0MVB4w&sW6PVtBb5df!2Q8#DRfPTSvVg%DQ!q1oq*FQXs',
        },
        body: JSON.stringify({
          isProcessed: false,
        }),
      });

      const data = await response.json();
      // console.log('Reload Response', data);

      if (data?.data?.schedules?.length !== 0)
        toast.success('Re - Uploaded Successfully');
      else {
        toast.error('No schedules found');
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
      localStorage.clear();
      navigate('/login');
    }
  };

  const handleEditClick = async (center: string) => {
    open();
    // console.log('Edit Clicked');

    const getFacultyData = async (center: string) => {
      const requestUrl =
        'https://sheets.googleapis.com/v4/spreadsheets/1OTnzm9gKVce4edD4qWZzeemvmjQknZbon5rLbc8xtAE/values/Teachers!A:E?key=AIzaSyAaq4k_2Bb_E9xTaJdEeQ9RmEvEu_1AxHQ';

      try {
        const response = await fetch(requestUrl, {
          method: 'GET',
        });

        const data: FacultyDataSheets = await response.json();

        // Getting the original data that we need to use
        const sheetData = data.values;

        // console.log('Original Faculty Data: ', sheetData);

        // Removing the headers from the sheet data
        sheetData?.shift();

        // Getting the data in the form [{center: string, faculty: [{name: string, email: string, driveId: string}]}]
        const outputData: FacultyData[] = [];

        sheetData?.forEach((facultyData) => {
          const center = facultyData[0];

          const name = facultyData[1];
          const email = facultyData[2];
          const driveId = facultyData[4];

          const faculty: Faculty = { name, email, driveId };

          // Now we have to check whether the center exsists in the outputData or not
          const exsistingCenter = outputData.find(
            (data) => data.center === center
          );

          if (exsistingCenter) {
            exsistingCenter.faculty.push({ ...faculty });
          } else {
            outputData.push({
              center,
              faculty: [{ ...faculty }],
            });
          }
        });

        const newCentersFaculty: CentersFaculty[] = [];

        const newData = outputData.filter((data) => data.center === center);

        

        newData[0]?.faculty?.forEach((faculty, index) =>
          newCentersFaculty.push({
            group: center,
            label: faculty.name,
            value: faculty.name,
            key: `${center}-${index}`,
          })
        );

        
        setCentersFaculty(newCentersFaculty);
      } catch (error) {
        toast.error('Error in fetching the Faculty data');
        localStorage.clear();
        navigate('/login');
      }
    };

    getFacultyData(center);
  };

  const youtubeId = document?.youtubeId;
  const fileType = document?.fileType;
  const scheduled = document?.scheduled;
  const uploaded = document?.uploaded;

  return (
    <div className={styles['action-container']}>
      {opened && (
        <EditDetails
          title={document?.fileName || ''}
          opened={opened}
          open={open}
          close={close}
          document={document}
          centersFaculty={centersFaculty}
        />
      )}

      {isPreviewModalOpen && (
        <PreviewModal
          title='Preview Video'
          opened={isPreviewModalOpen}
          open={openPreviewModal}
          close={closePreviewModal}
          document={document}
        />
      )}

      {fileType === 'video' && uploaded && (
        <Tooltip label='Preview the video' withArrow arrowSize={6}>
          <img
            src={Images.preview}
            alt='Preview Icon'
            onClick={openPreviewModal}
          />
        </Tooltip>
      )}

      {(fileType === 'video' || fileType === 'notes') && uploaded && !scheduled && (document.schedules?.length==0 || document.schedules?.length>1) && (
        // <Tooltip label='Find a schedule' withArrow arrowSize={6}>
        <Schedule document={document} title='Select' />
        // </Tooltip>
      )}

      {fileType === 'video' && scheduled && (
        <Tooltip label='Edit Thumbnail of the Video' withArrow arrowSize={6}>
          <img
            src={Images.edit}
            alt='Edit'
            onClick={() => handleEditClick(document.center)}
          />
        </Tooltip>
      )}
      <Tooltip label='Download File' withArrow arrowSize={6}>
        <img
          src={Images.link}
          alt='Link'
          onClick={() => handleLinkClick(document)}
        />
      </Tooltip>
      <Tooltip
        label='Search for schedule automatically'
        withArrow
        arrowSize={6}
      >
        <img
          src={Images.reload}
          alt='Reload'
          onClick={() => handleReloadClick(document)}
        />
      </Tooltip>
    </div>
  );
};

export default Actions;
