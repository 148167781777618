import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import fileDataType from '../../interface/data/FileData';

type InitialStateType = { data: fileDataType[]; isLoading: boolean };

const initialState: InitialStateType = {
  data: [],
  isLoading: false,
};

const fileDataSlice = createSlice({
  name: 'fileData',
  initialState,
  reducers: {
    setFileData(state, action: PayloadAction<fileDataType[] | []>) {
      // console.log('setFileData', action.payload);
      state.data = action.payload;
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
  },
});

export const { setFileData, setIsLoading } = fileDataSlice.actions;

export default fileDataSlice.reducer;
