import { useState, useEffect, useMemo, forwardRef } from 'react';

// Mantine
import { Select } from '@mantine/core';
import type { SelectItemProps } from '@mantine/core';

// Types
import OneOptionProps from '../../../interface/props/filter/OneOption';

// Redux
import type { RootState } from '../../../app/store';
import { useSelector, useDispatch } from 'react-redux';
import {
  setRoom,
  setFileType,
  handleReset,
} from '../../../features/filters/filterSlice';

type Props = {
  data: OneOptionProps[];
  label: string;
  placeholder?: string;
  nothingFound?: string;
};

const OneOption: React.FC<Props> = ({
  data,
  label,
  placeholder = 'Select an option',
  nothingFound = 'Nothing Found',
}) => {
  const state = useSelector((state: RootState) => state.filter.isReset);
  const center = useSelector((state: RootState) => state.filter.center);
  // console.log(
  //   'Is Room Disabled: ',
  //   label === 'Room' && center.length === 0 ? true : false
  // );

  const isReset = useMemo(() => {
    return state;
  }, [state]);

  const dispatch = useDispatch();

  const [value, setValue] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleChange = (val: string) => {
    // console.log('Value: ', val, 'Type of Value : ', typeof val);
    setValue(val);
    dispatch(handleReset(false));

    if (label === 'Room') {
      dispatch(setRoom(val));
    } else {
      dispatch(setFileType(val));
    }
  };

  const handleSearchChange = (val: any) => {
    // console.log('Value : ', val, 'Type of Value: ', typeof val);
    setSearchValue(val);
    dispatch(handleReset(false));
  };

  // console.log('Value from OneOption.tsx - useState : ', value);

  useEffect(() => {
    if (isReset) {
      setValue(null);
      setSearchValue('');
    }
  }, [isReset]);

  const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ label, ...others }: SelectItemProps, ref) => (
      <div ref={ref} {...others}>
        <div>{label}</div>
      </div>
    )
  );

  return (
    <Select
      data={data ? data : [{ label: 'hello', value: 'hello', key: 'hello' }]}
      value={value}
      disabled={label === 'Room' && center.length === 0 ? true : false}
      searchValue={searchValue}
      onSearchChange={handleSearchChange}
      itemComponent={SelectItem}
      onChange={handleChange}
      label={label}
      placeholder={placeholder}
      searchable
      nothingFound={nothingFound}
      clearable
      allowDeselect
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) || false
      }
      maxDropdownHeight={160}
      maw={120}
    />
  );
};

export default OneOption;
