// React
import { useEffect } from 'react';
// Types
import { PingSelect, setPingData } from '../features/data/pingSlice';
// Redux
import { useDispatch } from 'react-redux';

const useFetchPingData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPingData = async () => {
      const response = await fetch(
        'https://studio-app-api.penpencil.co/v1/studio/ping',
        {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('id_token') as string,
          },
        }
      );

      const pingData: PingSelect[] = [];
      const data = await response.json();

      const pings = data?.data?.sort();

      pings?.forEach((e: string) => {
        pingData.push({ value: e, label: e });
      });

      dispatch(setPingData(pingData));
    };

    fetchPingData();
  }, [dispatch]);

  return {};
};

export default useFetchPingData;
