import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Automatically Removes token after 15 minutes
const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('id_token')) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div
      style={{
        display: 'grid',
        placeItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <GoogleOAuthProvider clientId='292334676968-fbqug598dqreeihakv2tq6dlbat7tgs3.apps.googleusercontent.com'>
        <div
          style={{ width: '40vh', display: 'grid', placeItems: 'center' }}
          className='loginCard'
        >
          <p style={{ color: 'black', fontWeight: '600' }}>
            Login using your PW Google account
          </p>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              localStorage.setItem(
                'id_token',
                credentialResponse?.credential ?? ''
              );
              navigate('/');
              const expiryTime = new Date().getTime() + 60 * 60 * 1000;
              localStorage.setItem('expiry_time', expiryTime.toString());
            }}
            onError={() => {
              alert('Login Failed');
            }}
          />
        </div>
      </GoogleOAuthProvider>
    </div>
  );
};

export default Login;
