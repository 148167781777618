import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  FormattedFacultyDataSelect,
  FacultyData,
} from '../../interface/data/FacultyData';

interface idNameFacultyDataType {
  name: string;
  driveId: string;
}

interface FacultyDataType {
  originalData: FacultyData[] | null;
  formattedData: FormattedFacultyDataSelect[] | null;
  idNameFacultyData: idNameFacultyDataType[] | null;
}

const initialState: FacultyDataType = {
  originalData: null,
  formattedData: null,
  idNameFacultyData: null,
};

const facultyOptionSlice = createSlice({
  name: 'facultyOption',
  initialState,
  reducers: {
    setFacultyOption(
      state,
      action: PayloadAction<FormattedFacultyDataSelect[] | null>
    ) {
      state.formattedData = action.payload;
      // console.log(action.payload);
    },
    setFacultyData(state, action: PayloadAction<FacultyData[] | null>) {
      state.originalData = action.payload;
      // console.log(action.payload);
    },
    setFacultyIdNameData(
      state,
      action: PayloadAction<idNameFacultyDataType[] | null>
    ) {
      state.idNameFacultyData = action.payload;
      // console.log(action.payload);
    },
  },
});

export const { setFacultyData, setFacultyOption, setFacultyIdNameData } =
  facultyOptionSlice.actions;

export default facultyOptionSlice.reducer;
